import React from "react"
import "./trust.scss"

import TV from "../../images/reasons/television.svg"
import GBH from "../../images/reasons/GBH.svg"
import Radio from "../../images/reasons/radio.svg"
import { useMedia } from "use-media"

const Trust = () => {
  const isMobile = useMedia({ maxWidth: "500px" })
  return (
    <div className="reason trust">
      <div className="img-header">
        {!isMobile && <img src={Radio} alt="radio icon" height={60} />}
        <img src={GBH} alt="GBH logo" height={50} />
        {!isMobile && <img src={TV} alt="TV icon" height={60} />}
      </div>
      <div className="labels-row">
        {isMobile ? (
          <span className="label" style={{ whiteSpace: "pre-wrap" }}>
            <span>98%</span> of GBH 89.7 listeners & {"\n"}
            <span>94%</span> of GBH TV viewers say:
          </span>
        ) : (
          <>
            <span className="label">
              <span>98%</span> of GBH 89.7 listeners say:
            </span>
            <span className="label">
              <span>94%</span> of GBH TV viewers say:
            </span>
          </>
        )}
      </div>
      <div className="quote">
        GBH provides programming on issues that are important to me and my
        family.
      </div>

      <div className="labels-row">
        {isMobile ? (
          <span className="label" style={{ whiteSpace: "pre-wrap" }}>
            <span>94%</span> of GBH 89.7 listeners & {"\n"}
            <span>94%</span> of GBH TV viewers say:
          </span>
        ) : (
          <>
            <span className="label">
              <span>94%</span> of GBH 89.7 listeners say:
            </span>
            <span className="label">
              <span>94%</span> of GBH TV viewers say:
            </span>
          </>
        )}
      </div>
      <div className="quote">
        GBH provides information/content that I trust.
      </div>
    </div>
  )
}

export default Trust
