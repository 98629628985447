import React from "react"
import "./visibility.scss"
import { useMedia } from "use-media"

const visibilityData = [
  {
    label: "Television",
    top: ["29,684,000", "impressions"],
    bottom: ["1,215,000", "viewers"],
  },
  {
    label: "Radio",
    top: ["28,627,000", "impressions"],
    bottom: ["860,000", "listeners"],
  },
  {
    label: "Streaming",
    top: ["3,952,000", "streams"],
    bottom: ["398,000", "listeners"],
  },
  {
    label: "Digital",
    top: ["1,889,000", "impressions"],
    bottom: ["868,000", "users"],
  },
  {
    label: "Podcast",
    top: ["1,032,000", "downloads"],
    bottom: ["312,000", "listeners"],
  },
  {
    label: "Print",
    top: ["459,000", "impressions"],
    bottom: ["172,000", "recipients"],
  },
]

const renderItem = (item, i, isSmall) => {
  return (
    <li key={i} className="item">
      {isSmall ? (
        <>
          <div>
            <span>{item.label}</span>
          </div>
          <div>
            <span>{item.top[0]}</span>
            <span>{item.top[1]}</span>
          </div>
        </>
      ) : (
        <div>
          <span>
            {item.label} {item.top[0]}
          </span>
          <span>{item.top[1]}</span>
        </div>
      )}
      <div>
        <span>{item.bottom[0]}</span>
        <span>{item.bottom[1]}</span>
      </div>
    </li>
  )
}

const Visibility = () => {
  const isSmall = useMedia({ maxWidth: "710px" })
  return (
    <div className="reason visibility">
      <ul>
        <li className="total">
          <span>65,643,000+</span>
          <span>total impressions</span>
        </li>
        {visibilityData.map((item, i) => renderItem(item, i, isSmall))}
      </ul>
    </div>
  )
}

export default Visibility
