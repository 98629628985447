// common---
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicsContactForm from "../components/contactForm/dynamicsContactForm"
import Hero from "../components/hero"
import MediaKit from "../components/mediaKit"
import ReasonsModule from "../components/reasonsModule"
// --- end common

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      isTitleExtended={true}
      description="Build your business with a powerful GBH sponsorship in an uncluttered environment and align your brand with the nation's flagship public media organization."
    />
    <Hero
      header="Build Your Business with a Powerful GBH Corporate Sponsorship"
      subHeader={{
        tel: "617-300-3730",
        email: "localcorporatesponsorship@wgbh.org",
      }}
    />
    <ReasonsModule />
    <MediaKit />
    <DynamicsContactForm />
  </Layout>
)

export default IndexPage
