import React, { useState } from "react"

const Accordion = ({ number, title, children }) => {
  const [isOpen, setIsOpen] = useState(number === 1)

  const toggleAccordion = () => setIsOpen(prevState => !prevState)

  return (
    <div className="accordion">
      <div
        role="button"
        tabIndex={number}
        className="trigger"
        onKeyDown={toggleAccordion}
        onClick={toggleAccordion}
        aria-expanded={isOpen}
      >
        <h2 className="title">
          <span>{number}.</span> {title}
        </h2>
        <div className="icon">
          {isOpen ? (
            <span className="closed">&minus;</span>
          ) : (
            <span className="open">&#43;</span>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="content" role="region">
          {children}
        </div>
      )}
    </div>
  )
}

export default Accordion
