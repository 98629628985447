import React from "react"
import "./halo.scss"

import GBH from "../../images/reasons/GBH.svg"
import Radio from "../../images/reasons/radio.svg"
import TV from "../../images/reasons/television.svg"
import { useMedia } from "use-media"

const haloData = [
  {
    perc1: "79%",
    perc2: "71%",
    quote:
      "Patronizing companies that sponsor GBH is an effective way to support its programs.",
  },
  {
    perc1: "76%",
    perc2: "62%",
    quote:
      "I would prefer to buy a product or service from a company that sponsors GBH.",
  },
  {
    perc1: "61%",
    perc2: "47%",
    quote:
      "I would pay a little more to buy a product or service from a GBH sponsor opposed to a commercial advertiser.",
  },
]

const renderItem = ({ perc1, perc2, quote }, index, isMobile) => (
  <div key={index}>
    {isMobile ? (
      <div className="labels-row">
        <span className="label" style={{ whiteSpace: "pre-wrap" }}>
          <span>{perc1}</span> of GBH 89.7 listeners & {"\n"}
          <span>{perc2}</span> of GBH TV viewers say:
        </span>
      </div>
    ) : (
      <div className="labels-row">
        <span className="label">
          <span>{perc1}</span> of GBH 89.7 listeners say:
        </span>
        <span className="label">
          <span>{perc2}</span> of GBH TV viewers say:
        </span>
      </div>
    )}
    <div className="quote">{quote}</div>
  </div>
)

const Halo = () => {
  const isMobile = useMedia({ maxWidth: "500px" })
  return (
    <div className="reason halo">
      <div className="img-header">
        {!isMobile && <img src={Radio} alt="Radio icon" height={60} />}
        <img src={GBH} alt="GBH logo" height={50} />
        {!isMobile && <img src={TV} alt="TV icon" height={60} />}
      </div>
      {haloData.map((item, i) => renderItem(item, i, isMobile))}
    </div>
  )
}

export default Halo
