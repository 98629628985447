import React from "react"
import Accordion from "./accordion"
import {
  Trust,
  Visibility,
  Halo,
  Coverage,
  Reach,
  Breakthrough,
} from "./reasons"

const source1 =
  "TV: Nielsen Local NSI Live + 7, Reach + Frequency Report Nov. 2021–Jan. 2022 Average (WGBH, WGBX, WORLD), M–Su 3a–3a, P2+ | Radio: TAPSCAN Nov. 2021–Jan. 2022 Average (WGBH FM + WCRB FM), P6+ | Streaming: Stream Guys/SG Reports, Nov. 2021–Jan. 2022 Average | Digital: Google Analytics Nov. 2021–Jan. 2022 Average (wgbh.org + classicalwcrb.org) | Podcast: PRX Average Monthly Unique Listeners Nov. 2021–Jan 2022 (listeners) | Print: 89.7 WGBH/99.5 WCRB Member Survey: Fielded Feb 10–Feb 21, 2020 (impressions); EXPLORE GBH Circulation (monthly average) Feb. 2021–Jan. 2022 (recipients)"

const source2 =
  "Radio: GBH 89.7 Audience Survey, Fielded: 6/14/21 – 7/21/21  | TV : GBH 2 Audience Survey, Fielded: 6/17/21 – 7/21/21"

const source3 =
  "TV: Scarborough Doublebase 9/19–8/21, A18+, M–Su 4a–2a | Radio: Scarborough Doublebase 9/19–8/21, A18+, M–F 6a–7p Cume | Digital: Scarborough Doublebase 9/19–8/21, A18+, visited in last 30 days | Explore: Scarborough Doublebase 9/19–8/21, A18+, contributed $35+ to public radio or TV in last 12 months"

const source4 =
  "Radio: GBH 89.7 Audience Survey, Fielded: 6/14/21 – 7/21/21  | TV : GBH 2 Audience Survey, Fielded: 6/17/21 – 7/21/21"

const source5 =
  <div><b>Source:</b> Bar chart TV: MASTERPIECE, 2/20/22, 7p-8p | Media Monitors, CBS/60 Minutes, 2/20/22, 7p-8p, local and national buys | Bar chart radio: 89.7 GBH <em>Morning Edition</em>, 2/25/22, 9a–10a | Media Monitors, WEEI-FM, 2/17/22, 5a-6a, local and national buys</div>

const reasonsData = [
  {
    title: "Increase your visibility before a large audience",
    heading: "Each month GBH serves:",
    Component: Visibility,
    source: source1,
  },
  {
    title: "Associate your brand with America's most trusted institutions",
    heading: "When compared to commercial stations,",
    Component: Trust,
    source: source2,
  },
  {
    title: "Reach an educated, affluent, culturally-engaged audience",
    heading: "Compared to the average Boston adult 18+:",
    Component: Reach,
    source: source3,
  },
  {
    title: "Enhance your corporate image and bottom line",
    heading:
      "Compared to commercial media, the GBH audience is more likely to respond to a sponsor's message.",
    Component: Halo,
    source: source4,
  },
  {
    title: "Break through the media clutter",
    heading: () => (
      <>
        With far fewer promo minutes per hour than commercial broadcast,
        GBH sponsor spots stand out.
      </>
    ),
    Component: Breakthrough,
    source: source5,
  },
  {
    title: "Cover New England with television and radio",
    heading:
      "Connecting communities from the Cape to Greater Boston to the Berkshires.",
    Component: Coverage,
    source: null,
  },
]

const renderReason = ({ title, heading, Component, source }, index) => (
  <Accordion key={index} title={title} number={index + 1}>
    {heading && (
      <h2 className="reason_subhead">
        {typeof heading === "string" ? heading : heading()}
      </h2>
    )}
    <Component />
    {source && (
      <p className="reason_notes">
        {typeof source === "string"
          ? <><b>Source:</b> {source}</>
          : source
        }
      </p>
    )}
  </Accordion>
)

const ReasonsModule = () => (
  <>
    <div className="clearAll" />
    <div className="wrapper">
      <h1 className="reasons">Six Reasons to Sponsor GBH</h1>
      <div className="reasons-accordion">{reasonsData.map(renderReason)}</div>
    </div>
  </>
)

export default ReasonsModule
