import React from "react"
import coverage from "../../images/reasons/Reason-6.png"

const Coverage = () => (
  <p>
    <img
      className="center"
      alt="GBH Radio and Television Coverage Map"
      src={coverage}
      style={{ width: "1120px", height: "auto" }}
    />
  </p>
)

export default Coverage
