import React from "react"
import "./reach.scss"

import Piano from "../../images/reasons/piano.svg"
import Coin from "../../images/reasons/coin.svg"
import Home from "../../images/reasons/home.svg"
import Graduation from "../../images/reasons/graduation-mortarboard.svg"

const reachData = [
  {
    label: "GBH 89.7 radio listeners are:",
    content: [
      "122%",
      " more likely to attend an ",
      "opera or classical music concert",
    ],
    image: Piano,
  },
  {
    label: "EXPLORE GBH readers are:",
    content: ["46%", " more likely to have a ", "household income of $250K+"],
    image: Coin,
  },
  {
    label: "GBH television viewers are:",
    content: ["57%", " more likely to own a home worth ", "$1MM+"],
    image: Home,
  },
  {
    label: "GBH.org visitors are:",
    content: ["66%", " more likely to have a ", "post-graduate degree"],
    image: Graduation,
  },
]

const renderItem = ({ label, content, image }, index) => (
  <div className="item" key={index}>
    <span className="label">{label}</span>
    <div className="content">
      <span className="perc">{content[0]}</span>
      {content[1]}
      <span>{content[2]}</span>
    </div>
    <img className="image" src={image} alt="" height={60} width={60} />
  </div>
)

const Reach = () => (
  <div className="reason reach">
    <div className="items">{reachData.map(renderItem)}</div>
  </div>
)

export default Reach
