import React from "react"

const Hero = ({ header, subHeader }) => (
  <div id="hero">
    <div className="wrapper">
      <div className="image-bg"></div>

      <div className="content">
        <h1 className="banner-header">{header}</h1>
        <h3 className="banner-subheader">
          <a href={`mailto:${subHeader.email}`}>{subHeader.email}</a>
          <br />
          <a href={`tel:${subHeader.tel}`}>{subHeader.tel}</a>
        </h3>

        <a href="#contactAnchor" className="contact-btn">
          Contact Us
        </a>
      </div>
    </div>
  </div>
)

export default Hero
