import React from "react"
import { Script } from "gatsby"

function DynamicsContactForm({ children }) {

  return (
    <>
      <div className="wrapper">
        <div className="contact-us">
          <div className="anchor-link" id="contactAnchor">
            <h1 className="contact-header">Contact Us</h1>
            <h2 className="phone-header">
              <a href="tel:617-300-3730">617-300-3730</a>
            </h2>
            <div className="contact-subheader bold">Tell us your business goals and a member of our team will reach out to you to discuss our multiplatform sponsorship packages.</div>
            <div className="contact-subheader">
              Please note that this form is for corporate sponsorship of GBH. For all other inquiries, please contact Audience & Member Services (<a href='https://www.wgbh.org/foundation/contact' target='_blank'>https://www.wgbh.org/foundation/contact</a>).
            </div>
          </div>
          <div className="contact-form">
            <iframe frameBorder="0" id="fbbcf790-f584-4823-be8d-bd1c6e95e87d" title="Local Corporate Sponsorship contact form"></iframe>
            <Script>
              var sandboxSrc = "https://e3e4f08ec83141108758883611cfde94.svc.dynamics.com/t/formsandbox/NLxJkjxmF6-t-VLz1U6vTZVOAQkh5WIhIGjMqIxuRsA/af25ab77-dec1-ed11-83ff-000d3a57a1b4?ad=" + encodeURIComponent(document.location.toString()); 
              document.getElementById('fbbcf790-f584-4823-be8d-bd1c6e95e87d').setAttribute('src', sandboxSrc); 
            </Script>
          </div>
         </div>
        </div>

    </>
  )
}

export default DynamicsContactForm
