import React from "react"
import "./breakthrough.scss"

import GBH_897 from "../../images/reasons/GBH_897.svg"
import GBH_2 from "../../images/reasons/GBH-2-rgb-color.svg"
import Chart_897 from "../../images/reasons/Bars_89.7_text.png"
import Chart_2 from "../../images/reasons/Bars_GBH2_Text.png"
import Radio from "../../images/reasons/radio.svg"
import TV from "../../images/reasons/television.svg"

const radioChartAltText = "A bar chart compares 3 minutes and 15 seconds of sponsor time per hour on GBH television with 15 minutes and 30 seconds per hour of commercial television."
const tvChartAltText = "A bar chart compares 4 minutes and 15 seconds of sponsor time per hour on GBH television with 14 minutes and 30 seconds per hour of commercial television."

const Breakthrough = () => (
  <div className="reason breakthrough">
    <div className="item">
      <img src={GBH_2} alt="GBH TV logo" height={50} />
      <div className="item-content">
        <img src={Chart_2} alt={tvChartAltText} height={380} />
        <div className="text">
          <span>Commercial television has </span>
          <span className="accent">almost 5 times</span>
          <span> the number of sponsor minutes and promos in comparison to GBH television</span>
          <img src={TV} alt="TV icon" height={60} />
        </div>
      </div>
    </div>
    <div className="item">
      <img src={GBH_897} alt="GBH Radio 89.7 logo" height={50} />
      <div className="item-content">
        <img src={Chart_897} alt={radioChartAltText} height={380} />
        <div className="text">
          <span>Commercial radio has </span>
          <span className="accent">over 3 times</span>
          <span> the number of sponsor minutes and promos in comparison to GBH 89.7</span>
          <img src={Radio} alt="radio icon" height={60} />
        </div>
      </div>
    </div>
  </div>
)

export default Breakthrough
